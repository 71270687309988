import {
  GET_USER_EMAILS,
  GET_USER_EMAILS_SUCCESS,
  GET_USER_EMAILS_ERROR,
  SEND_MAIL_REPLIES,
  SEND_MAIL_REPLIES_SUCCESS,
  SEND_MAIL_REPLIES_ERROR,
  UPDATE_USER_EMAIL,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_USER_EMAIL_ERROR,
  CLEAR_USER_EMAIL_ERROR,
  ARCHIVE_USER_EMAIL,
  ARCHIVE_USER_EMAIL_SUCCESS,
  ARCHIVE_USER_EMAIL_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  getUserEmailsLoading: false,
  userEmails: {},
  getUserEmailsError: false,
  sendMailRepliesLoading: false,
  sendMailRepliesSuccess: false,
  sendMailRepliesError: false,
  updateUserEmailLoading: false,
  updateUserEmailSuccess: false,
  updateUserEmailError: false,
  archiveUserEmailLoading: false,
  archiveUserEmailSuccess: false,
  archiveUserEmailError: false,
};

const Mailbox = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_EMAILS: {
      return {
        ...state,
        getUserEmailsLoading: true,
      };
    }
    case GET_USER_EMAILS_SUCCESS: {
      return {
        ...state,
        getUserEmailsLoading: false,
        userEmails: action.payload,
      };
    }
    case GET_USER_EMAILS_ERROR: {
      return {
        ...state,
        getUserEmailsLoading: false,
        getUserEmailsError: true,
      };
    }

    case SEND_MAIL_REPLIES: {
      return {
        ...state,
        sendMailRepliesLoading: true,
      };
    }

    case SEND_MAIL_REPLIES_SUCCESS: {
      return {
        ...state,
        sendMailRepliesLoading: false,
        sendMailRepliesSuccess: true,
      };
    }
    case SEND_MAIL_REPLIES_ERROR: {
      return {
        ...state,
        sendMailRepliesError: true,
        sendMailRepliesLoading: true,
      };
    }
    case UPDATE_USER_EMAIL: {
      return {
        ...state,
        updateUserEmailLoading: true,
      };
    }

    case UPDATE_USER_EMAIL_SUCCESS: {
      return {
        ...state,
        updateUserEmailLoading: false,
        updateUserEmailSuccess: true,
      };
    }
    case UPDATE_USER_EMAIL_ERROR: {
      return {
        ...state,
        updateUserEmailError: true,
        updateUserEmailLoading: true,
      };
    }
    case ARCHIVE_USER_EMAIL: {
      return {
        ...state,
        archiveUserEmailLoading: true,
      };
    }

    case ARCHIVE_USER_EMAIL_SUCCESS: {
      return {
        ...state,
        archiveUserEmailLoading: false,
        archiveUserEmailSuccess: true,
      };
    }
    case ARCHIVE_USER_EMAIL_ERROR: {
      return {
        ...state,
        archiveUserEmailError: true,
        archiveUserEmailLoading: true,
      };
    }

    case CLEAR_USER_EMAIL_ERROR: {
      return {
        ...state,
        getUserEmailsError: false,
        sendMailRepliesError: false,
        updateUserEmailError: false,
        updateUserEmailLoading: false,
        sendMailRepliesLoading: false,
        sendMailRepliesSuccess: false,
        archiveUserEmailLoading: false,
        archiveUserEmailSuccess: false,
        archiveUserEmailError: false,
      };
    }
    default:
      return state;
  }
};

export default Mailbox;
