import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import leadsSagas from './Leads';
import leadSagas from './Lead';
import enrichSagas from './Enrich';
import onboardingSagas from './onboarding';
import webhooksSagas from './webhooks';
import csvfileSagas from './CSVfile';
import inviteSagas from './Invite';
import websites from './Websites';
import Members from './Members';
import Utilities from './Utilities';
import Tags from './Tags';
import List from './List';
import LeadScoring from './LeadScoring';
import Comments from './Comments';
import sessionPlayer from './SessionPlayer';
import editProfile from './EditProfile';
import blacklist from './Blacklist';
import hubspot from './Hubspot';
import icp from './Icp';
import gtm from './Gtm';
import prospector from './Prospector';
import prospectorCSV from './ProspectorCSV';
import automation from './Automation';
import integrations from './Integrations';
import EmailCampaign from './EmailCampaign';
import contacts from './Contacts';
import rating from './Rating';
import personalizeModal from './PersonalizeModal';
import prospects from './Prospects';
import services from './Services';
import settings from './Settings';
import pipedrive from './Pipedrive';
import slack from './Slack';
import zoho from './Zoho';
import salesforce from './Salesforce';
import Mailbox from './Mailbox';

export default function* rootSaga() {
  yield all([
    authSagas(),
    leadsSagas(),
    leadSagas(),
    enrichSagas(),
    onboardingSagas(),
    webhooksSagas(),
    csvfileSagas(),
    inviteSagas(),
    websites(),
    Members(),
    Utilities(),
    Tags(),
    List(),
    LeadScoring(),
    Comments(),
    sessionPlayer(),
    editProfile(),
    blacklist(),
    hubspot(),
    salesforce(),
    zoho(),
    icp(),
    gtm(),
    prospector(),
    prospects(),
    contacts(),
    automation(),
    prospectorCSV(),
    integrations(),
    EmailCampaign(),
    rating(),
    personalizeModal(),
    services(),
    settings(),
    pipedrive(),
    slack(),
    Mailbox(),
  ]);
}
