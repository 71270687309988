import {
  GET_USER_EMAILS,
  GET_USER_EMAILS_SUCCESS,
  GET_USER_EMAILS_ERROR,
  SEND_MAIL_REPLIES,
  SEND_MAIL_REPLIES_SUCCESS,
  SEND_MAIL_REPLIES_ERROR,
  UPDATE_USER_EMAIL,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_USER_EMAIL_ERROR,
  ARCHIVE_USER_EMAIL,
  ARCHIVE_USER_EMAIL_SUCCESS,
  ARCHIVE_USER_EMAIL_ERROR,
  CLEAR_USER_EMAIL_ERROR,
} from '../../constants/ActionTypes';
export const getUserEmails = (id) => {
  return {
    type: GET_USER_EMAILS,
    payload: id,
  };
};

export const getUserEmailsSuccess = (data) => {
  return {
    type: GET_USER_EMAILS_SUCCESS,
    payload: data,
  };
};

export const getUserEmailsError = () => {
  return {
    type: GET_USER_EMAILS_ERROR,
  };
};

export const sendMailReplies = (data) => {
  return {
    type: SEND_MAIL_REPLIES,
    payload: data,
  };
};

export const sendMailRepliesSuccess = (data) => {
  return {
    type: SEND_MAIL_REPLIES_SUCCESS,
    payload: data,
  };
};

export const sendMailRepliesError = () => {
  return {
    type: SEND_MAIL_REPLIES_ERROR,
  };
};
export const updateUserEmails = (data) => {
  return {
    type: UPDATE_USER_EMAIL,
    payload: data,
  };
};
export const archiveUserEmail = (data) => {
  return {
    type: ARCHIVE_USER_EMAIL,
    payload: data,
  };
};

export const updateUserEmailsSuccess = (data) => {
  return {
    type: UPDATE_USER_EMAIL_SUCCESS,
    payload: data,
  };
};

export const updateUserEmailsError = () => {
  return {
    type: UPDATE_USER_EMAIL_ERROR,
  };
};
export const archiveUserEmailsSuccess = (data) => {
  return {
    type: ARCHIVE_USER_EMAIL_SUCCESS,
    payload: data,
  };
};

export const archiveUserEmailsError = () => {
  return {
    type: ARCHIVE_USER_EMAIL_ERROR,
  };
};
export const clearMailboxErrors = () => {
  return {
    type: CLEAR_USER_EMAIL_ERROR,
  };
};
