import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';
import { ARCHIVE_USER_EMAIL, GET_USER_EMAILS, SEND_MAIL_REPLIES, UPDATE_USER_EMAIL } from '../../constants/ActionTypes';

import {
  getUserEmailsSuccess,
  getUserEmailsError,
  sendMailRepliesError,
  sendMailRepliesSuccess,
  updateUserEmailsError,
  updateUserEmailsSuccess,
  clearMailboxErrors,
  archiveUserEmailsError,
  archiveUserEmailsSuccess,
} from '../actions/Mailbox';

import { generalErrorMessage, generalSuccessMessage } from '../actions/GeneralMessage';

import { userSignOut } from '../actions/Auth';

const fetchUserEmailsRequest = async (websiteId, status, limit, searchQuery, page) =>
  await axios
    .get(
      `${baseUrl}/admin/master-inbox/emails?websiteId=${websiteId}&status=${status}&page=${page}&limit=${limit}&searchQuery=${searchQuery}`
    )
    .then((response) => response)
    .catch((error) => error);

const sendMailReplies = async (emailId, body) =>
  await axios
    .post(`${baseUrl}/admin/master-inbox/send-reply`, {
      emailId,
      body,
    })
    .then((response) => response)
    .catch((error) => error);
const updateUserEmail = async (emailId, body) =>
  await axios
    .put(`${baseUrl}/admin/master-inbox/update-email`, {
      mailBoxId: emailId,
      body,
    })
    .then((response) => response)
    .catch((error) => error);
const archiveUserEmail = async (emailId, prospectId) =>
  await axios
    .put(`${baseUrl}/admin/master-inbox/archive-email`, {
      emailId,
      prospectId,
    })
    .then((response) => response)
    .catch((error) => error);

function* fetchUserEmails({ payload }) {
  setAuthorizationHeader();
  const { websiteId, status, limit, searchQuery, page } = payload;
  try {
    const callResponse = yield call(fetchUserEmailsRequest, websiteId, status, limit, searchQuery, page);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getUserEmailsError());
      } else {
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getUserEmailsError());
      }
    } else {
      yield put(getUserEmailsSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getUserEmailsError());
    yield put(generalErrorMessage(error));
  }
}

function* sendMailRepliesData({ payload }) {
  const { emailId, body } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(sendMailReplies, emailId, body);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(sendMailRepliesError());
        yield put(clearMailboxErrors());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(sendMailRepliesError());
        yield put(clearMailboxErrors());
      }
    } else {
      yield put(generalSuccessMessage('Reply sent successfully'));
      yield put(sendMailRepliesSuccess(callResponse.data));
      yield put(clearMailboxErrors());
    }
  } catch (error) {
    yield put(sendMailRepliesError());
    yield put(generalErrorMessage(error));
    yield put(clearMailboxErrors());
  }
}
function* updateUserEmailData({ payload }) {
  const { emailId, body } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(updateUserEmail, emailId, body);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(updateUserEmailsError());
        yield put(clearMailboxErrors());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(updateUserEmailsError());
        yield put(clearMailboxErrors());
      }
    } else {
      yield put(generalSuccessMessage('Email updated successfully'));
      yield put(updateUserEmailsSuccess(callResponse.data));
      yield put(clearMailboxErrors());
    }
  } catch (error) {
    yield put(updateUserEmailsError());
    yield put(clearMailboxErrors());
    yield put(generalErrorMessage(error));
  }
}

function* archiveUserEmailData({ payload }) {
  const { emailId, prospectId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(archiveUserEmail, emailId, prospectId);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(archiveUserEmailsError());
        yield put(clearMailboxErrors());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(archiveUserEmailsError());
        yield put(clearMailboxErrors());
      }
    } else {
      yield put(generalSuccessMessage('Email archived successfully'));
      yield put(archiveUserEmailsSuccess(callResponse.data));
      yield put(clearMailboxErrors());
    }
  } catch (error) {
    yield put(archiveUserEmailsError());
    yield put(clearMailboxErrors());
    yield put(generalErrorMessage(error));
  }
}

export function* getRequestFetchUserEmails() {
  yield takeEvery(GET_USER_EMAILS, fetchUserEmails);
}

export function* sendMailRepliesRequest() {
  yield takeEvery(SEND_MAIL_REPLIES, sendMailRepliesData);
}

export function* updateUserEmailRequest() {
  yield takeEvery(UPDATE_USER_EMAIL, updateUserEmailData);
}
export function* archiveUserEmailRequest() {
  yield takeEvery(ARCHIVE_USER_EMAIL, archiveUserEmailData);
}

export default function* rootSaga() {
  yield all([fork(getRequestFetchUserEmails)]);
  yield all([fork(sendMailRepliesRequest)]);
  yield all([fork(updateUserEmailRequest)]);
  yield all([fork(archiveUserEmailRequest)]);
}
